<template>
	<v-divider v-bind="$attrs" v-on="$listeners"></v-divider>
</template>

<script>
/**
 * @displayName w-divider
 *
 * @since 0.3.2
 */
export default {
	name: 'WDivider'
}
</script>
